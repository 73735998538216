import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Views from "./views";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./translations/i18n";
import { useFirestoreConnect } from "react-redux-firebase";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/en-gb";
import i18next from "i18next";
import {
  FIRESTORE_CUSTOMER_REQUESTS_TABLE,
  FIRESTORE_CUSTOMERS_TABLE,
  FIRESTORE_INVITED_USERS_TABLE,
  FIRESTORE_NOTIFICATIONS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_REGIONS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "./constants/FirestoreConstant";
import { COMPANY_COUNTRY_CODE } from "./constants/CompanyConstants";

moment.locale(i18next.language);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  // Fetch all collections from Firestore and put in redux store
  useFirestoreConnect([
    {
      collection: FIRESTORE_CUSTOMERS_TABLE,
      where: [
        ["customerRegion", "==", COMPANY_COUNTRY_CODE],
        ["status", "==", "active"],
      ],
    },
    {
      collection: FIRESTORE_CUSTOMERS_TABLE,
      where: [
        ["customerRegion", "==", COMPANY_COUNTRY_CODE],
        ["status", "==", "new"],
      ],
      storeAs: "newCustomers",
    },
    { collection: FIRESTORE_INVITED_USERS_TABLE },
    {
      collection: FIRESTORE_REGIONS_TABLE,
      doc: COMPANY_COUNTRY_CODE,
      subcollections: [{ collection: FIRESTORE_NOTIFICATIONS_TABLE }],
      storeAs: FIRESTORE_NOTIFICATIONS_TABLE,
    },
    { collection: FIRESTORE_CUSTOMER_REQUESTS_TABLE, where: ["rejected", "!=", true] },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: [
        ["userRole", "in", ["admin", "superAdmin"]],
      ],
      storeAs: "cmsUsers",
    },
  ]);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
};

export default App;
