export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Chef's Farm SG",
  STREET_AND_NUMBER: "Street name and house number",
  ZIP_AND_CITY: "Zipcode and city",
  COUNTRY: "Singapore",
  KVK: "",
  VAT: "",
  WEBSITE_URL: "shop.chefsfarm.nl",
  WEBSITE_NAME: "Chef's Farm SG",
};
export const LOGO_SRC = "/img/logo.png";
export const COMPANY_COUNTRY_CODE = "SG";

export const REGIONS = ["NL", "KW", "SG"];
